/* @font-face {
    font-family: 'DB Helvethaica X';
    src: url('db_helvethaica_x_med_ext_v3.2-webfont.woff2') format('woff2'),
        url('db_helvethaica_x_med_ext_v3.2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'sukhumvit-set';
    src: url('SukhumvitSet-Text.woff2') format('woff2'),
        url('SukhumvitSet-Text.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
