/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system\
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

* {
    font-family: Sukhumvit-Set;
  }
  